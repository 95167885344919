/* eslint-disable import/no-anonymous-default-export */
export default {
  token: {
    colorBgLayout: "rgb(255, 255, 255)",
    fontSize: 16, // 20 ,18, 16 ,14
    colorText: "#6C6C6C",
    // colorPrimaryTextHover: "rgba(0, 0, 0, 0.6)",
    colorTextDisabled: "rgba(0, 0, 0, 0.6)",
    colorPrimary: "#63619a",
    colorEY: "#DDAA00",
    colorHeaderBg: "rgb(106, 104, 161)",
    colorHeaderText: "#FFF",
    colorPageHeader: "rgb(108, 108, 108)",
    colorTableHeader_light: "#F5F5F5",
    colorTableHeaderText_light: "#6A68A1",
    colorTableHeaderText_dark: "#FFF",
    colorTableHeader_dark: "#6A68A1",
    colorTableBodyBorder: "#e6e6e6",
    // colorTableCellRowHover: "rgba(236, 236, 93, 0.22)",
    colorTableCellRowHover: "#F9F0DF",
    colorTableOnCellRow: "rgba(255,170,0, .2)",
    colorTableLight: "#FFF",
    colorTableDark: "rgb(249 250 251)",
    take: "#FFB300",
    trans: "#6A68A1",
    tableBg: "#EBEDF9",
  },
  components: {
    Modal: {
      wireframe: true, //線條風格
      colorBgElevated: "#ffffff",
    },
    Button: {
      colorText: "#6C6C6C",
      colorPrimaryTextHover: "#FFF",
    },
    DatePicker: {
      colorPrimary: "#9FB2FC",
    },
    Menu: {
      fontSize: 16,
    },
    Tabs: {
      // fontSize: 20,
      colorPrimaryActive: "rgba(106, 104, 161, 0.9)",
      colorPrimary: "rgb(106, 104, 161)",
      colorPrimaryHover: "rgba(106, 104, 161, 0.8)",
    },
    Form: {
      margin: 0,
      marginLG: 0,
      marginXS: 0,
      marginXXS: 0,
      // paddingXS: 2,
    },
    Table: {
      // colorTextHeading: "#6A68A1",
      padding: 6, //上下間距
      paddingContentVertical: 6,
      paddingContentVerticalLG: 6,
      paddingContentVerticalSM: 6,
      paddingLG: 6,
      paddingMD: 6,
      paddingSM: 6,
      paddingXL: 6,
      paddingXS: 6,
    },
    Splitter: {
      resizeSpinnerSize: 80,
      splitBarSize: 3,
      splitTriggerSize: 20,
    },
  },
};
